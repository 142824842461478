/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import styles from "./MobileCategory.module.css"
import LoadingNew from '../LoadingNew/LoadingNew';
import { CartContext } from '../CartContext';




function MobileCategory(props) {
    const { setShowCategory } = props;
    const {categories}= useContext(CartContext)
    const [showMenu, SetShowMeny] = useState('menu')
  
    const handleMenu = () => {
        SetShowMeny('menu')
    }
    const handleCate = () => {
        SetShowMeny('cate')
    }
    const [isLogin, setIsLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const checkLoginStatus = async () => {
            const token = Cookies.get('login_token');
            if (token) {
                setIsLogin(true);
            } else {
                setIsLogin(false);
            }
            setIsLoading(false);
        };

        checkLoginStatus();
    }, []);

    return (
        <div className={styles.mobileCategory}>
                {isLoading ? <LoadingNew /> : null}
            <div>
                <button type='button' onClick={() => setShowCategory(false)} className='btn '><FontAwesomeIcon icon={faClose} /></button>
            </div>
            <Fade>
                <div className='d-flex ms-2'>
                    <button  type='button' className=' btn btn-outline-dark' onClick={handleCate}>
                        Categries
                    </button>
                    <button type='button' className='  ms-2 btn btn-outline-dark' onClick={handleMenu}>
                        Menu
                    </button>
                </div>

                {
                    showMenu === 'menu' ?
                        <div>
                            <ul>
                                <li onClick={() => setShowCategory(false)}><Link to="/">HOME</Link></li>
                                <li onClick={() => setShowCategory(false)}><Link to="/shop">SHOP</Link></li>
                                <li onClick={() => setShowCategory(false)}><Link to="/">BLOG</Link></li>
                                <li onClick={() => setShowCategory(false)}><Link to="pages/contact">OUR STORE</Link></li>
                                <li onClick={() => setShowCategory(false)}><Link to="/pages/about">ABOUT</Link></li>
                                <li onClick={() => setShowCategory(false)}> {isLogin ? <Link to="/profile">Profile</Link> : <span> <Link to='/login'>Log in </Link> / <Link to='/registration'>Registration</Link></span>}</li>
                            </ul>
                        </div> :
                        <div>
                            <ul>
                                {
                                    categories && categories.map(data =>
                                        <Link onClick={() => setShowCategory(false)} key={data.id} to={`category/${data.slug}`}>   <li>  {data.name}</li></Link>
                                    )
                                }
                            </ul>
                        </div>
                }


            </Fade >

        </div >
    );
};

export default MobileCategory;