/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { LuSearch } from "react-icons/lu";
import { Fade } from "react-awesome-reveal";
import styles from './Nav.module.css'
// import Logo from '../Logo/bachai.png'

function Nav() {

    const pathnameX = window.location.pathname;
    const isHomePage = pathnameX === '/'
    const [isScrolled, setIsScrolled] = useState(false);
    const [showSrcBtn, setShowSrcBtn] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 450);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSeachShow = () => {
        setShowSrcBtn(!showSrcBtn)
    }



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <div>
            <div className={styles.main}>
                <div>
                    <div style={!isHomePage ? { display: "none" } : null} className={styles.navx}>
                        <div>
                            <Link to='/'>
                                <h3>III Soft</h3>
                                {/* <img className={styles.logo} src={Logo} alt='logo' width={160} /> */}
                            </Link>
                        </div>
                        <div>
                            <ul>
                                <li><Link to="/">HOME</Link></li>
                                <li><Link to="/products">PRODUCTS</Link></li>
                                <li><Link to="/">TESTMONIAL</Link></li>
                                <li><Link to="/pages/contact">CONTACT US</Link></li>
                                <li onClick={handleSeachShow} ><LuSearch /></li>


                            </ul>
                        </div>

                    </div>
                    {showSrcBtn ? <SearchSection /> : null}

                </div>

                <nav className={`${styles.navbarx} ${isScrolled || !isHomePage ? styles.scrolled : ''}`}>
                    <div className={styles.nav2nd}>
                        <div className={styles.navx}>
                            <div>
                                <Link to='/'>
                                    <h3>III Soft</h3>
                                    {/* <img className={styles.logo} src={Logo} alt='logo' width={160} /> */}
                                </Link>
                            </div>
                            {/* <div className={styles.search}>
                            <input onChange={handelSearch} value={serachInput} className={styles.searchBox} type="text" placeholder='Search here..' />
                            <FontAwesomeIcon onClick={search} className={styles.searchIcon} icon={faSearch} />

                        </div> */}
                            <div>
                                <ul>
                                    <li><Link to="/">HOME</Link></li>
                                    <li><Link to="/products">PRODUCTS</Link></li>
                                    <li><Link to="/">TESTMONIAL</Link></li>
                                    <li><Link to="/pages/contact">CONTACT US</Link></li>
                                    <li onClick={handleSeachShow} ><LuSearch /></li>

                                </ul>
                            </div>
                        </div>
                        {showSrcBtn ? <SearchSection /> : null}
                        {/* <div className={styles.headerCate}>
                            {
                                showCate ?

                                    <Slide direction='down'>
                                        <ul>
                                            {
                                                categroy && categroy.map(data =>
                                                    <Link key={data.id} to={`/category/${data.slug}`}>  <li onClick={handleShowCate}>{data.name} </li></Link>
                                                )
                                            }
                                        </ul>
                                    </Slide>
                                    : null}
                        </div> */}
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Nav;




function SearchSection() {

    const [serachInput, setSearchInput] = useState('')
    const handelSearch = (e) => {
        setSearchInput(e.target.value)
    }
    const router = useNavigate()
    const search = () => {
        if (serachInput.length > 0) {
            router(`search/${serachInput}`)
        }
    }


    return (
        <Fade direction='down'>
            <div className={styles.serachCateSection}>
                <div className={styles.serachCate2nd}>
                    <div>
                        <div className={styles.search}>
                            <input onChange={handelSearch} value={serachInput} className={styles.searchBox} type="text" placeholder='Search here..' />
                            <FontAwesomeIcon onClick={search} className={styles.searchIcon} icon={faSearch} />

                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    )
}