/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import React, { useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPixel from 'react-facebook-pixel';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FcPhone } from 'react-icons/fc';
import { FiMail } from 'react-icons/fi';
import styles from './PosPage.module.css'
import image1 from '../../Media/pos-img-1.PNG'
import image2 from '../../Media/pos-img-2.PNG'
import image3 from '../../Media/pos-img-3.PNG'
import image4 from '../../Media/pos-img-4.PNG'
import image5 from '../../Media/pos-img-5.PNG'
import CarouselImages from '../CarouselImages/CarouselImages';

function PosPage() {
    const aspectRatio = 9 / 16;
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const slider = [
        {
            image: image1,
            title: "Homepage",
            des: "Here is an overview of your business"
        },
        {
            image: image2,
            title: "Products",
            des: "Here you can see the products list,  add a new prduct, stock manage, and also you can manage units, variations, categories, brand and warranties"
        },
        {
            image: image3,
            title: "Sales",
            des: "In the sales section you can see your sales list, add a new sell and manage other neseccity things "
        },
        {
            image: image4,
            title: "Reports",
            des: "Here you can see the report of the every single section of your business like - profit report, purchase report, sell report, return report, tax report etc. "
        },
        {
            image: image5,
            title: "Contacs",
            des: "Here you can see list of your customers and suppliers"
        },

    ]

    const pixelId = '1205120680706464';
    ReactPixel.init(pixelId);
    function getFBP() {
        const decodedCookies = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookies.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            const cookie = cookieArray[i].trim();
            if (cookie.startsWith('_fbp=')) {
                return cookie.substring(5); // Remove '_fbp='
            }
        }

        return ''; // Return an empty string if fbp cookie is not found
    }

    function getFBC() {
        const decodedCookies = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookies.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            const cookie = cookieArray[i].trim();
            if (cookie.startsWith('_fbc=')) {
                return cookie.substring(5); // Remove '_fbc='
            }
        }

        return ''; // Return an empty string if fbc cookie is not found
    }
    // window.dataLayer = window.dataLayer || [];
    // dataLayer.push({
    //     'event': 'fbCookies',
    //     'fbp': getFBP(),
    //     'fbc': getFBC()
    // });
    const pixelData = {
        content_name: 'POS Software',
        content_type: 'Software',
        client_user_agemt: window.navigator.userAgent,
        'fbp': getFBP(),
        'fbc': getFBC(),

    }
    useEffect(() => {
       

        ReactPixel.trackSingle(pixelId, 'ViewContent', pixelData);
    }, []);
    const intiateCheckout = () => {
       
        ReactPixel.track('InitiateCheckout', pixelData)
        // ReactPixel.trackSingle(pixelId, 'InitiateCheckout', pixelData);
    }
    return (
        <div className={styles.webMain}>
            <Helmet>
                <title>POS Software for resturant, super shop, pharmacy etc !III Soft</title>
                <meta
                    name='description'
                    content='Custom E-Commerce website with fb pixel set up. '
                />

            </Helmet>
            <div>
                <h1>POS Software for resturant, super shop, pharmacy etc or any others business.</h1>
                <h3>For any type of business</h3>
                <div className='mt-5 text-center'>
                    <p>আরো বিস্তারিত জানতে বা software নিতে সরাসরি যোগাযোগ করুন</p>
                    <button className='uniBtn' type='button' onClick={() => intiateCheckout()}> <a className='text-decoration-none text-light' aria-label='call me' href="tel:+88001862799857" target="_blank" rel="noopener noreferrer"><FcPhone />  Phone - 01862799857</a> </button>
                    <button className='uniBtn' type='button' onClick={() => intiateCheckout()}><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://api.whatsapp.com/send?phone=+8801862799857&text=Hello'><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp - 01862799857 (ক্লিক করুন)</a> </button>
                    <button className='uniBtn' type='button' onClick={() => intiateCheckout()}><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://facebook.com/iiisoft'><FontAwesomeIcon icon={faFacebook} /> Facebook (ক্লিক করুন)</a> </button>
                    <button className='uniBtn' type='button' onClick={() => intiateCheckout()}> <a className='text-decoration-none text-light' aria-label='sent mail to me' href="mailto:+aslam1nip@gmail.com" target="_blank" rel="noopener noreferrer"><FiMail /> Email (ক্লিক করুন) </a> </button>
                    <p>অথবা   <button className='btn' type='button' onClick={() => intiateCheckout()}><a className='text-decoration-none' aria-label='fill the form' target="blank" href='https://forms.gle/QWvXnCc98gK39TTF7'> এই খানে ক্লিক করে </a></button> ফর্ম টি পূরণ করুন, আমরা আপনার সাথে যোগাযোগ করবো </p>

                </div>
                <div>
                    <p className='text-center'>---------------------------------------</p>
                    <p className='text-center my-3'>Sample Images</p>
                    <CarouselImages carouselList={slider} />
                </div>

                <div className={styles.detailsSec}>
                    <h4>বিস্তারিত ---</h4>
                    <ul>
                        <li>
                            এটি একটি অনলাইন বেস POS software, তাই এটি যেকোনো জায়গায় যেকোনো ডিভাইস দিয়ে ব্যবহার করতে পারেবন।
                        </li>
                        <li>
                            আপনি একটি সফটওয়্যার দিয়ে মাল্টিপল লোকেশনে  মাল্টিপল বিজনেস ম্যানেজ করতে পারবেন।
                        </li>
                        <li>
                            আপনি দৈনন্দিন, সাপ্তাহিক, মাসিক সব ধরনের আয়-ব্যয় / কেনা-বেচা/ লাভ-ক্ষতির  হিসাব রাখতে পারবেন।
                        </li>
                        <li>
                            এই সফটওয়্যার দিয়ে আপনি আপনার প্রোডাক্ট সংক্রান্ত সকল হিসাব রাখতে পারবেন, যেমন - প্রোডাক্ট পার্সেস, সেল, রিটির্ন, স্টোক, ড্যামেজ ইত্যাদি হিসাব রাখতে পারবেন।
                        </li>
                        <li>
                            সেল সংক্রান্ত হিসাব, যেমন - কোন  Employee/ Sales Manager কতটি আইটেম, কি আইটেম সেল করলো তার হিসাব রাখতে পারবেন।
                        </li>
                        <li>
                            কমিশন বেজ কেউ সেল করলে তার হিসাবও রাখতে পারবেন।
                        </li>
                        <li>
                            Reports সেকশন থেকে আপনি purchase, sell, products, stocks, suppliers, customers, tax, Sales Manager ইত্যাদির রিপোর্ট সহজেই পেয়ে যাবেন।
                        </li>
                        <li>Essantial section থেকে চাইলে আপনি office staff দের to do list set করে দিতে পারবেন, এছাড়া মাল্টিপল লোকশনে বিজনেস থাকলে এক location থেকে অন্য location এ ম্যাসেজ পাঠাতে পারবেন। Document save, Memo create করে রাখতে পারবেন।</li>
                        <li>HRM সেকশন থেকে আপনি staff দের attendence, leave, holiday, payment, sells target ইত্যাদি ম্যানেজ করতে পারবেন। </li>

                    </ul>
                </div>
                <div>
                    <h2>Price</h2>
                    <h5 className='my-5'>
                        দাম - <span className='text-danger'>৫০০০ </span>টাকা (ডোমেইন, হোস্টিং খরচ আপনার), <br />

                    </h5>
                    <p className='text-danger'>
                        এছাড়া  যদি কিস্তিতে নিতে চান  আলোচনা ক যেতে পারে।
                    </p>
                </div>
                <div>
                    <p className='text-center mt-5'>Video Demo</p>
                    <div
                        style={{
                            position: 'relative',
                            paddingBottom: `${aspectRatio * 100}%`, // Set the aspect ratio as padding-bottom
                            height: 0,
                            overflow: 'hidden',
                            marginTop: '20px',

                        }}
                    >
                        <iframe
                            title="YouTube Video"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                            }}
                            src='https://www.youtube.com/embed/n70bkfgd1gk'
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                </div>
                <div className='mt-5 text-center'>
                    <p>আরো বিস্তারিত জানতে বা software নিতে সরাসরি যোগাযোগ করুন</p>
                    <p> <a className='text-decoration-none text-light' aria-label='call me' href="tel:+88001862799857" target="_blank" rel="noopener noreferrer"><FcPhone />  Phone - 01862799857</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://api.whatsapp.com/send?phone=+8801862799857&text=Hello'><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp - 01862799857 (ক্লিক করুন)</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://facebook.com/iiisoft'><FontAwesomeIcon icon={faFacebook} /> Facebook (ক্লিক করুন)</a> </p>
                    <p> <a className='text-decoration-none text-light' aria-label='sent mail to me' href="mailto:+aslam1nip@gmail.com" target="_blank" rel="noopener noreferrer"><FiMail /> Email (ক্লিক করুন) </a> </p>
                    <p>অথবা <a className='text-decoration-none' aria-label='fill the form' target="blank" href='https://forms.gle/QWvXnCc98gK39TTF7'> এই খানে ক্লিক করে </a> ফর্ম টি পূরণ করুন, আমরা আপনার সাথে যোগাযোগ করবো </p>

                </div>
            </div>

        </div>
    );
}

export default PosPage;