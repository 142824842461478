import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FcPhone } from 'react-icons/fc';
import { FiMail } from 'react-icons/fi';
import styles from './WebsitePage.module.css'
import image1 from '../../Media/signup.svg'

function WebsitePage() {
    const aspectRatio = 9 / 16;
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={styles.webMain}>
            <Helmet>
                <title>Custom E-Commerce website !III Soft</title>
                <meta
                    name='description'
                    content='Custom E-Commerce website with fb pixel set up. '
                />

            </Helmet>
            <div>
                <h1>Custom E commerce website with facebook pixel and conversion api set up</h1>
                <h3>for start up business</h3>
                <div className='mt-5 text-center'>
                    <p className='r'>লাইভ ডেমো দেখতে এখানে ক্লিক করুন</p>
                    <p>আরো বিস্তারিত জানতে বা ওয়েসাইট সরাসরি নিতে যোগাযোগ করুন</p>
                    <p> <a className='text-decoration-none text-light' aria-label='call me' href="tel:+88001862799857" target="_blank" rel="noopener noreferrer"><FcPhone />  Phone - 01862799857</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://api.whatsapp.com/send?phone=+8801862799857&text=Hello'><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp - 01862799857 (ক্লিক করুন)</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://facebook.com/iiisoft'><FontAwesomeIcon icon={faFacebook} /> Facebook (ক্লিক করুন)</a> </p>
                    <p> <a className='text-decoration-none text-light' aria-label='sent mail to me' href="mailto:+aslam1nip@gmail.com" target="_blank" rel="noopener noreferrer"><FiMail /> Email (ক্লিক করুন) </a> </p>
                    <p>অথবা <a className='text-decoration-none' aria-label='fill the form' target="blank" href='https://forms.gle/QWvXnCc98gK39TTF7'> এই খানে ক্লিক করে </a> ফর্ম টি পূরণ করুন, আমরা আপনার সাথে যোগাযোগ করবো </p>
                </div>
                <div>
                    <p className='text-center'>---------------------------------------</p>
                    <p className='text-center my-3'>Sample Images</p>
                    <img src={image1} alt="ecommerce site" />
                </div>

                <div>
                    <h4>বিস্তারিত ---</h4>
                    <ul>
                        <li>
                            Custom E commerce website যা react framwork এবং django দিয়ে বানানো।
                        </li>
                        <li>
                            কোনো কিছু কাস্টমাইজ করার দরকার হলে করে নিতে পারবেন।
                        </li>
                        <li>
                            এইটা website টি ইউজার ফ্রেন্ডলি এবং SEO Frinedly. <br />
                            আপনি গুগলের https://pagespeed.web.dev/ এই সাইট থেকে সবকিছু টেস্ট করে নিতে পারবেন।
                        </li>
                        <li>
                            ওয়েবসাইট টি খুবই দ্রুত লোড হয় (১-২ সেকেন্ডের মধ্যে), আপনি লোকাল, শেয়ার হোস্টিং নিলেও ভালো সার্ভিস পাবেন।
                        </li>
                        <li>
                            একটি ওয়েবসাইট নিলেই তো হবে না আপনার ই কমার্স বিজনেস করতে হলে অবশ্যই facebook pixel set up করতে হবে, তাই <br />
                            আমাদের কাছ থেকে নিলে আপনাকে আমরা datalayer enable, facebook pixel set up এবং facebook conversation api set করে দিবো
                        </li>
                        <li>
                            উপরে ওয়েবসাইট এর কিছু sample pictures এবং নিচে একটি ভিডিও দেয়া আছে।
                        </li>
                    </ul>
                </div>
                <div>
                    <h2>Price</h2>
                    <h5>
                        এককালীন নিলে  দাম - <span className='text-danger'>১০০০০ </span>টাকা (ডোমেইন, হোস্টিং খরচ আপনার), <br />
                        আর যদি কিস্তিতে নিতে চান সেক্ষেত্রে প্রতি মাসে ২ হাজার করে ৬ মাসে ১২০০০ টাকা  (ডোমেইন, হোস্টিং খরচ আপনার)। <br />
                        এছাড়া আপনি চাইলে trail হিসেবে ১ মাস ফ্রি ব্যবহার করে দেখতে পারবেন, এজন্য আপনার অবশ্যই ডোমেইন + হোস্টিং বা শুধু ডোমেইন থাকতে হবে।
                    </h5>
                    <p className='text-danger'>
                        এছাড়া আপনার বাজেট এবং রিকয়েরমেন্ট অনুযায়ী আলোচনা সাপেক্ষে দাম বাড়ানো কমানো যেতে পারে।
                    </p>
                </div>
                <div>
                    <p className='text-center mt-5'>Video Demo</p>
                    <div
                        style={{
                            position: 'relative',
                            paddingBottom: `${aspectRatio * 100}%`, // Set the aspect ratio as padding-bottom
                            height: 0,
                            overflow: 'hidden',
                            marginTop: '20px',

                        }}
                    >
                        <iframe
                            title="YouTube Video"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                            }}
                            src='https://www.youtube.com/embed/75DrmknAQks'
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                </div>
                <div className='mt-5 text-center'>
                    <p>আরো বিস্তারিত জানতে বা ওয়েসাইট সরাসরি নিতে যোগাযোগ করুন</p>
                    <p> <a className='text-decoration-none text-light' aria-label='call me' href="tel:+88001862799857" target="_blank" rel="noopener noreferrer"><FcPhone />  Phone - 01862799857</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://api.whatsapp.com/send?phone=+8801862799857&text=Hello'><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp - 01862799857 (ক্লিক করুন)</a> </p>
                    <p><a className='text-decoration-none text-light' aria-label='message on whatsapp' target="blank" href='https://facebook.com/iiisoft'><FontAwesomeIcon icon={faFacebook} /> Facebook (ক্লিক করুন)</a> </p>
                    <p> <a className='text-decoration-none text-light' aria-label='sent mail to me' href="mailto:+aslam1nip@gmail.com" target="_blank" rel="noopener noreferrer"><FiMail /> Email (ক্লিক করুন) </a> </p>
                    <p>অথবা <a className='text-decoration-none' aria-label='fill the form' target="blank" href='https://forms.gle/QWvXnCc98gK39TTF7'> এই খানে ক্লিক করে </a> ফর্ম টি পূরণ করুন, আমরা আপনার সাথে যোগাযোগ করবো </p>
                </div>
            </div>

        </div>
    );
}

export default WebsitePage;